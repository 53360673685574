// import logo from './logo.svg';
import './App.css';
import Background from './Background';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid } from '@mui/material';
// import Copyright from './Copyright';
import { UseSpring } from 'react-spring';
import PlaxContent from './PlaxContent';
import Copyright from './Copyright';
import styles from './styles.module.css';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import ContactIcon from '@mui/icons-material/Contacts';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import EmailIcon from '@mui/icons-material/Email';
import RObotIcon from '@mui/icons-material/PrecisionManufacturing';
import { BackgroundContext } from './Contexts';
import { InView } from 'react-intersection-observer';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Fab from '@mui/material/Fab';
import { useThree } from 'react-three-fiber';
import logo from './asset/LogoCOL2.5.png';
import Forms, { FormStateContext } from './Forms';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Swarms from './Swarms.js.old';
// import { Parallax } from '@react-spring/parallax';
// import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { Trailer, spanCharacters, spanArrayStringEqualSplit, useElementOnScreen } from './Animations';
import Homepage2, { fadeIn } from './Homepage2';
import { delay, motion } from 'framer-motion';

// import ColorTabs from './ColorTabs';


export const fontStyle = { fontFamily: 'saira semi condensed', color: cyan(2), borderRadius: 0 };
const devmode = false;

const year = 2025;

const swarmsStyle = {
  borderRadius: '5px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '80vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export function cyan(opacity) {
  switch (opacity) {
    case 0: return "#0d5b4e";
    case 1: return "#107262";
    case 2: return "#148e7a";
    case 3: return "#19b299";
    case 4: return "#ca6924";
    case 5: return "#868279";
    default: return "#0d5b4e";
  }
}

const transitTimeouts = { appear: 400, enter: 325, exit: 325 };

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

// function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
//   // const [homeElProg, setHomeElProg] = useState(0);
//   // const [lowestHomeElProg, setLowestHomeElProg] = useState(1);

//   useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//       // setHomeElProg(0)
//       // setLowestHomeElProg(1)
//     }

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return windowDimensions;
// }

// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

function App() {

  // const [menuOpen, setMemuOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [itemTranslate, setItemTranslate] = useState(0);
  const [frameloop, setFrameloop] = useState('always');
  const [focusing, setFocusing] = useState(null);
  const [focuszoom, setFocuszoom] = useState(true);
  const { height, width } = getWindowDimensions();
  const [enquirysubmitted, setEnquirysubmitted] = useState(null);
  const [homeElProg, setHomeElProg] = useState(0);
  const [prevHomeElProg, setPrevHomeElProg] = useState(0);
  const [lowestHomeElProg, setLowestHomeElProg] = useState(1);
  const [webRTC, setWebRTC] = useState(false);
  const [bgAnimate, setBgAnimate] = useState('always');
  // const [pages, setPages] = useState(2.85)
  const contactFromRef = useRef(null);
  const parallax = useRef(null);

  const [sectionMainRef, sectionMainVisible] = useElementOnScreen({ root: null, permanent: true })
  const [sectionDatadivRef, sectionDatadivVisible] = useElementOnScreen({ root: null, permanent: true })
  // const [sectionContactsRef, sectionContactsVisible] = useElementOnScreen({ root: null, permanent: true })

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });
  //   // console.log('changes')


  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [])

  // useEffect(() => {
  //   function handleResize() {
  //     // var width,height = getWindowDimensions()
  //     // setWindowDimensions({ width, height });
  //     setWindowDimensions(getWindowDimensions());
  //     setPrevHomeElProg(homeElProg)
  //     setHomeElProg(0)
  //     setLowestHomeElProg(1)
  //     // setPages(height > width ? 2.85 : 31)
  //   }

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);


  // const handleScroll = (event) => {
  //   let scrollTop = event.srcElement.body.scrollTop,
  //     it = Math.min(0, scrollTop / 3 - 60);
  //   setItemTranslate(scrollTop)
  //   console.log(homeElProg)
  // }


  return (<div className='w-screen'>
    {/* <Modal
        open={webRTC}
        onClose={() => { setWebRTC(false); setBgAnimate('always'); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={swarmsStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Drone Streams
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Showcasing drone swarms over M2M communication infrastructures.
          </Typography>
          <br />
          <Swarms />
        </Box>
      </Modal> */}

    {/* <Trailer open={true} behaviour={{ x: 0, y: 0, from: { opacity: 0 }, to: { opacity: 1 }, delay: 1500 }}>
        <Button
          style={{ color: cyan(3), border: 'solid', borderRadius: '2px', bottom: '-78vh', zIndex: 100, opacity: 0.6 }}
          endIcon={<EmailIcon />}
          onClick={() => {
            console.log('contactform');
            contactFromRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }}
        >Contact Us</Button>
      </Trailer>
      <br /> */}

    <span style={{
      background: `linear-gradient(#141414, #132020 , #309f8d)`,
      position: 'fixed',
      // top: 0, left: 0, 
      height: '100vh', width: '100vw',
      // margin: 0, padding: 0, 
      // overflowY: 'none',
      backdropFilter: 'blur(5px)'
    }} />



    <BackgroundContext.Provider value={{ frameloop, focusing, bgAnimate }}>
      <Background mode={1} rainDelay={450} bgAnimate={bgAnimate} />
    </BackgroundContext.Provider>



    <Homepage2>

      {/* <div > */}
      <span style={{
        display: 'inline-flex',
        // transform: transform,
        // left: '50vw',
        placeContent: 'center',
        width: '100vw',
        position: 'relative',
        top: '14vh',
        // ...loginSprings
      }}>
        <Trailer open={true} behaviour={{ x: 0, y: 0, delay: 1500 }}>
          {spanCharacters("DATACORRIDOR", "[text-shadow:_0_2px_4px_rgb(0_0_0_/_0.4)]", { ...fontStyle, fontWeight: 'normal', position: 'sticky', top: `${height > width ? 24 : 22}vh`, fontSize: `${height > width ? 0.55 * 10 : 0.8 * 9}vh`, color: "#009f85" })}
        </Trailer>
      </span>

      <span style={{
        display: 'inline-flex',
        // transform: transform,
        // left: '50vw',
        placeContent: 'center',
        width: '100vw',
        position: 'relative',
        top: height > width ? '17vh' : '19vh',
        // ...loginSprings
      }}>
        <Trailer open={true} behaviour={{ x: 0, y: 0, delay: 2300 }}>
          {spanCharacters(spanArrayStringEqualSplit("Data Science and Innovation House", 4), "[text-shadow:_0_2px_4px_rgb(0_0_0_/_0.8)]", { ...fontStyle, fontSize: `${height > width ? 0.6 * 4 : 0.8 * 4}vh`, color: 'silver', opacity: 0.9, display: 'block' })}
        </Trailer>
      </span>

      <Trailer open={true} behaviour={{ x: 0, y: 0, from: { opacity: 0 }, to: { opacity: 1 }, delay: 4000 }}>
        <span className='[text-shadow:_0_2px_4px_rgb(0_0_0_/_0.6)]' style={{ paddingTop: '55vh', ...fontStyle, fontSize: `14px`, color: 'silver', opacity: 0.8, display: 'block' }}>&#169; {year} DATA CORRIDOR LIMITED.</span>
      </Trailer>


      {/* <motion.div
              variants={fadeIn}
              className="mt-[50vh]"
            >
              <motion.span className='text-zinc-200 opacity-80'> &#169; {2025} DATA CORRIDOR LIMITED.</motion.span>
            </motion.div> */}

      {/* </div> */}

    </Homepage2>






    {/* <div style={{ marginTop: '5vh', textAlign: 'center', textAlign: '-webkit-center', backdropFilter: 'blur(5px)' }}>
        <hr style={{ width: '80vw' }} className='style-two' />
        <h1 style={{ marginTop: '6vh', marginBottom: `0px`, ...fontStyle, fontSize: `${height > width ? 0.6 * 5 : 5}vh`, color: 'lightgrey', opacity: 0.9, display: 'block' }}> Data Corridor Limited</h1>

        <hr style={{ width: '40vw' }} className='style-two' />

        <h3 style={{ ...fontStyle, fontSize: `${height > width ? 0.4 * 5 : 2}vh`, color: 'lightgrey', opacity: 0.9, display: 'block' }}>Fostering a Culture of Excellence</h3>
        <Trailer containerRef={sectionMainRef} open={sectionMainVisible} behaviour={{ display: 'ruby-text', delay: 0, x: 0, y: 0, from: { opacity: 0 }, to: { opacity: 1 } }} behaviourConfig={{ duration: 500 }}>
          <p style={{ textAlign: 'justify', display: 'inline-block', ...fontStyle, paddingLeft: '30px', paddingRight: '30px', maxWidth: '700px', fontSize: `${height > width ? 16 : 18}px`, color: 'silver', opacity: 0.9 }}>
            Here at Data Corridor Limited, we believe that a great company culture is the foundation of success. We have worked tirelessly to create an environment where everyone feels valued, respected, and empowered. We strive to foster a culture that is inclusive, collaborative, and innovative.
            <br /><br />
            Our team is made up of individuals who are passionate about what they do and who work together to deliver visions. We believe that by valuing and investing in our people, we are creating a sustainable and successful business.
            <br /><br />
            We are committed to creating an environment that promotes growth and development, both personally and professionally. We provide our team members with opportunities to learn, grow, and take on new challenges. We also recognize and reward hard work and dedication.
            <br /><br />
            Our commitment to our culture doesn't stop there, we believe that a great culture should also be felt by our clients and partners. We strive to build strong relationships with our clients and partners by consistently delivering high-quality work, being responsive to their needs, and going above and beyond to exceed their expectations.
            <br /><br />
            At Datacorridor Limited, we believe that a great culture leads to great results. We are proud of the culture we have built and are dedicated to maintaining and improving it, for the benefit of our team, clients, and partners. Join us and be part of our culture of excellence.
          </p>
        </Trailer>

        <br />
        <br />
        <br />
        <br />
        <hr style={{ width: '80vw' }} className='style-two' />

        <h2 style={{ marginTop: '6vh', marginBottom: `0px`, ...fontStyle, fontSize: `${height > width ? 0.6 * 5 : 5}vh`, color: 'lightgrey', opacity: 0.9, display: 'block' }}> Data Division</h2>
        <hr style={{ width: '40vw' }} className='style-two' />

        <h3 style={{ ...fontStyle, fontSize: `${height > width ? 0.4 * 5 : 2}vh`, color: 'lightgrey', opacity: 0.9, display: 'block' }}>Unlock the Power of Your Data with Our Expertise</h3>
        <Trailer containerRef={sectionDatadivRef} open={sectionDatadivVisible} behaviour={{ display: 'ruby-text', delay: 0, x: 0, y: 0, from: { opacity: 0 }, to: { opacity: 1 } }} behaviourConfig={{ duration: 500 }}>
          <p style={{ textAlign: 'justify', display: 'inline-block', ...fontStyle, paddingLeft: '30px', paddingRight: '30px', maxWidth: '700px', fontSize: `${height > width ? 16 : 18}px`, color: 'silver', opacity: 0.9 }}>
            At our Data Division in Data Corridor Limited, we believe in the transformative power of data. We are a team of experienced data scientists and software developers who are passionate about helping businesses harness the potential of their data to drive growth and success, and to meet their visions.
            <br /><br />
            Our team of experts has a wealth of experience across a wide range of tech related skills, including software development, data science, cloud computing and more. We use cutting-edge technologies and methodologies to extract valuable insights from your data or make use of them in cunning ways to your benefit, and we work closely with you to understand your unique needs and goals.
            <br /><br />
            We offer a wide range of services, including:
            <ul>
              <li>Data analysis and visualization</li>
              <li>Predictive modeling and machine learning</li>
              <li>Software development and integration</li>
              <li>Cloud computing and big data solutions</li>
              <li>3D graphics web-based applications</li>
            </ul>
            At our Data Division, we are committed to delivering results that drive real business value. Our team is dedicated to working with you to understand your challenges and opportunities, and to develop customized solutions that help you achieve your goals.
            <br /><br />
            Don't let your data go to waste. Contact us today to find out how we can help you unlock the power of data and achieve your goals.
          </p>
        </Trailer>
        <br />



        <br />
        <br />
        <br />

        <hr style={{ width: '80vw' }} className='style-two' />
        <br />

        <h2
          ref={contactFromRef}
          style={{ marginBottom: `0px`, ...fontStyle, fontSize: `${height > width ? 0.6 * 5 : 5}vh`, color: 'lightgrey', opacity: 0.9, display: 'block' }}> Contact Us</h2>
        <hr style={{ width: '40vw' }} className='style-two' />
        <br />
        <p style={{ textAlign: 'justify', display: 'inline-block', ...fontStyle, paddingLeft: '30px', paddingRight: '30px', maxWidth: '700px', fontSize: `${height > width ? 16 : 18}px`, color: 'silver', opacity: 0.9 }}>
          Email <span style={{ color: 'white' }} >enquiries@datacorridor.io</span> for more information or to start a discussion with us.
          <br />
          <br />
          Or, submit the form below and we will get back to you!</p>
        <br />
        <br />
        <div style={{ maxWidth: '700px', margin: 'auto', paddingLeft: '30px', paddingRight: '30px' }}
        >
          <FormStateContext.Provider value={{ submitted: enquirysubmitted, setSubmitted: (content) => setEnquirysubmitted(content) }}>
            <Forms width height />
          </FormStateContext.Provider>
        </div>

        <div style={{ height: '80px' }} />
        <span style={{ ...fontStyle, fontSize: '12px', color: 'silver', opacity: 0.9, display: 'block' }}> &#169; {year} DATA CORRIDOR LIMITED.</span>
        <div style={{ height: "6vh" }} />
      </div> */}

  </div >
  );

}

export default App;
